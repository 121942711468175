import React, { useEffect } from "react";
import { Avatar, Spin } from "antd";
import { IoCalendarOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import Moment from "react-moment";
import { useGetPagesDetailQuery } from "../../store/loginRegisterSlice";
import { useSelector } from "react-redux";
import { fileUrl } from "../../Helper";

const CmsPages = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const cleanPathname = pathname.slice(1);
    const { data: pageDetails, isLoading } = useGetPagesDetailQuery(cleanPathname);
    const state = useSelector((state) => state?.persistedReducer?.banner);

    // const { data: pageDetails, isLoading } = useGetpageDetailsQuery(
    //     location?.state?.data
    // );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {isLoading ? (
                <Spin
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    size="large"
                />
            ) : (
                <div className="singleBlogWrapper">
                    <div
                        className="pageHeader"
                        style={{
                            backgroundImage: (() => {
                              if (location?.pathname === "/about-us") {
                                return `url(${fileUrl}storage/${state?.about})`;
                              } else if (location?.pathname === "/privacy-policy") {
                                return `url(${fileUrl}storage/${state?.privacy_policy})`;
                              } else if (location?.pathname === "/terms-and-condition") {
                                return `url(${fileUrl}storage/${state?.terms_of_service})`;
                              } else {
                                return `url('../../assets/images/contactUs.jpg')`;
                              }
                            })(),
                          }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pageHeading">
                                        <h3>{pageDetails?.page?.title}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="innerWrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8">
                                    <div className="card detailsCard blogCardSection">
                                        <div className="card-body">
                                            <div className="blogContent">
                                            <p className="title text-capitalize">
                                                    {" "}
                                                    {pageDetails?.page?.keywords}:
                                                </p>
                                                <p className="text">{pageDetails?.page?.summary}</p>
                                                <h3 className="blogTitle">{pageDetails?.page?.title}</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: pageDetails?.page?.description,
                                                    }}
                                                />
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>

            )}
        </>
    );
};

export default CmsPages;
