export const fetchDetail_Url =
  window.location.hostname === "localhost"
    ? "https://api.rainfrog.monetyz.com/api/"
    : window.location.hostname === "rainfrog.aleaspeaks.com"
      ? "https://rainfrog-api.aleaspeaks.com/api/"
      : "https://api.rainfrog.monetyz.com/api/";

export const fileUrl =
  window.location.hostname === "localhost"
    ? "https://api.rainfrog.monetyz.com/"
    : window.location.hostname === "rainfrog.aleaspeaks.com"
      ? "https://rainfrog-api.aleaspeaks.com/api/"
      : "https://api.rainfrog.monetyz.com/";

export const google_Api_Key = "AIzaSyB2HzK8YTpIHuhGG-cGB7wjCUKM-8inYD0";
