import React from "react";
import { Empty, Spin } from "antd";
import { useGetPagesDetailQuery } from "../../store/loginRegisterSlice";
import { useSelector } from "react-redux";
import { fileUrl } from "../../Helper";

const TermsAndConditions = () => {
    const{data:getTerms,isLoading} = useGetPagesDetailQuery('terms-and-condition')
    const state = useSelector((state)=>state?.persistedReducer?.banner)
   
    return (
        <>
         {isLoading? 
            <div className="spinContainer">
            <Spin size="large" />
        </div>: getTerms?.page && getTerms?.page.status == 1?
      <div className="termsWrapper">
            <div className="pageHeader" style={{
            backgroundImage: state?.terms_of_service
              ? `url(${fileUrl}storage/${state?.terms_of_service})`
              : `url('../../assets/images/contactUs.jpg')`,
          }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pageHeading">
                                <h3>Terms of Service</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card termsCard">
                                <div className="card-header">
                                    <h4 className="card-title">
                                    {getTerms?.page?.title}
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="textSection">
                                        <p className="title">{getTerms?.page?.keywords}</p>
                                        <p className="text">
                                           {getTerms?.page?.summary}
                                        </p>
                                       
                                        <div dangerouslySetInnerHTML={{ __html: getTerms?.page?.description}}></div>
                                        
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>: <div className="emptyContainer">
                    <Empty />
                </div>}
       
        </>
       
    );
};

export default TermsAndConditions;