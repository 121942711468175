import React, { useState } from "react";
import { Collapse, Table } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { BiTransferAlt } from "react-icons/bi";
import { MdOutlineFileDownload } from "react-icons/md";
import { useGetTransactionQuery } from "../../store/getSlice";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { fileUrl } from "../../Helper";

const { Panel } = Collapse;

const TransactionSummary = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;
    const { data: getTransaction } = useGetTransactionQuery()

    function formatString(str) {
        // Remove underscores and convert to lowercase
        const noUnderscores = str.replace(/_/g, ' ');

        // Capitalize the first letter
        const formatted = noUnderscores.charAt(0).toUpperCase() + noUnderscores.slice(1);

        return formatted;
    }


    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => {
                const startSerial = (currentPage - 1) * pageSize + 1;
                return startSerial + index;
            },
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transaction_id',
            key: 'transaction_id',
        },
        {
            title: 'Date',
            render: (text, record) => (
                <Moment format='MM/DD/YYYY'>{record?.created_at}</Moment>
            ),
        },
        {
            title: 'Amount',
            render: (text, record) => {
                const amount = parseFloat(record?.amount);

                if (record?.status === 'refunded' || record?.status === 'partial_refunded') {
                    // Format the amount as currency without the '+' sign
                    const formattedAmount = amount ? amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }) : 'N/A';

                    // Prepend '+' or '-' based on the amount
                    const displayAmount = amount > 0 ? `+$${formattedAmount.slice(1)}` : formattedAmount;

                    return (
                        <span>{displayAmount}</span>
                    );
                } else {
                    // Format the amount as currency without the '+' sign
                    const formattedAmount = amount ? amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }) : 'N/A';

                    // Prepend '+' or '-' based on the amount
                    const displayAmount = amount > 0 ? `-$${formattedAmount.slice(1)}` : formattedAmount;

                    return (
                        <span>{displayAmount}</span>
                    );
                }
            },

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: ((record) => (
                <>
                    {formatString(record)}
                </>
            ))
        },
        {
            title: 'Recurring Interval',
            dataIndex: 'interval',
            key: 'interval',
            render: ((record, index) => (
                <>
                {index.status === "success" ? record : '-'}
                </>
            ))
        },
        {
            title: 'Download Invoice',
            dataIndex: 'invoice',
            key: 'invoice',
            render: (text, record) => (
                <NavLink
                    to={`${fileUrl}storage/invoices/${record?.transaction_id}.pdf`}
                    target="_blank"
                    className="btn downloadBtn"
                >
                    <MdOutlineFileDownload />
                </NavLink>
            ),
        },

    ];

    return (
        <div className="transactionWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sectionHeader">
                            <h3 className="sectionHeading">Transaction Summary</h3>
                        </div>
                        <div className="sectionContent">
                            <Collapse
                                accordion
                                ghost
                                bordered={false}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
                                expandIconPosition='end'
                                className="transactionCollapse"
                                defaultActiveKey={[1]}
                                items={[
                                    {
                                        key: '1',
                                        label: <p className="panelHeader"><BiTransferAlt /> View Transactions</p>,
                                        children: (
                                            <div className="tableWrapper table-responsive">
                                                <Table
                                                    className="transactionTable"
                                                    dataSource={getTransaction?.data?.map(item => ({ ...item, key: item.id }))}
                                                    columns={columns}
                                                    pagination={{
                                                        pageSize,
                                                        current: currentPage,
                                                        onChange: page => setCurrentPage(page),
                                                    }}
                                                />
                                            </div>
                                        ),
                                    },
                                ]}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionSummary;