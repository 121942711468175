import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { Logo } from "../../constant/images";
import { TbHomeCheck } from "react-icons/tb";
import { FaRegUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { currentAddress, logout } from "../../store/authSlice";
import { useGetUserDetailQuery } from "../../store/getSlice";
import { Image } from "antd";
import { fetchDetail_Url, fileUrl } from "../../Helper";

const Navbar = ({ menuItems }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const state = useSelector((state) => state?.persistedReducer?.webData)
    const location = useLocation();
    const { data: userDetail, refetch } = useGetUserDetailQuery();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleNavigate = () => {
        navigate('/my-homes');
    }

    const handleAccountNavigate = () => {
        navigate('/my-account');
    }

    const handleProfileNavigate = () => {
        navigate('/my-profile');
    }

    const handleLogout = () => {
        dispatch(logout())
        dispatch(currentAddress(null));
        localStorage.clear();
        navigate("/login");
    };

    const handleItemClick = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (dropdownRef.current) {
            const dropdown = new window.bootstrap.Dropdown(dropdownRef.current);
            if (isOpen) {
                dropdown.show();
            } else {
                dropdown.hide();
            }
        }
    }, [isOpen]);
    useEffect(() => {
        if (!menuItems) {
            handleLogout()
        }
    }, [menuItems])

    useEffect(() => {
        refetch()
    }, [])

    const isBlogActive = location.pathname === '/blogs' || location.pathname.startsWith('/blog-details');

    const isFinanceActive = location.pathname === "/mortgage-calculator" || location.pathname === "/credit_card";
    const groupMenuItems = (menuItems) => {
        const groupedMenuItems = {};


        menuItems?.forEach((item) => {
            groupedMenuItems[item.id] = { ...item, children: [] };
        });

        menuItems?.forEach((item) => {
            if (item.parent_id && groupedMenuItems[item.parent_id]) {
                groupedMenuItems[item.parent_id].children.push(groupedMenuItems[item.id]);
            }
        });

        // Return only the top-level parents
        return Object.values(groupedMenuItems).filter((item) => item.parent_id === 0);
    };
    const groupedItems = groupMenuItems(menuItems);

    return (
        <nav className="navbar navbar-expand-lg customNavbar">
            <div className="container">
                <NavLink className="navbar-brand mobileShow" to={"/homepage"}>
                    <img src={state?.logo ? `${fileUrl}storage/${state?.logo}` : Logo} className="img-fluid" alt="logo" />
                </NavLink>
                <ul className="dflex mobileShow">
                    <li className="nav-item dropdown">
                        <NavLink className="headerBtn dropdown-toggle dropdownBtn" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <FaRegUserCircle />
                        </NavLink>
                        <ul className="dropdown-menu customDropdwn">
                            <li><button type="button" className="dropdown-item" to={"#"} onClick={() => handleAccountNavigate()} >My Account</button></li>
                            <li><button type="button" className="dropdown-item" to={"#"} onClick={() => handleProfileNavigate()} >My Profile</button></li>
                            <li><button type="button" className="dropdown-item" to={"#"} onClick={() => handleLogout()} >Log Out</button></li>
                        </ul>
                    </li>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </ul>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {groupedItems.map((item) => {
                            if (item?.alignment === "left" && item?.display_sections?.includes("header")) {
                                return item.children.length > 0 ? (
                                    <li className="nav-item dropdown" key={item.id}>
                                        <NavLink
                                            className={`nav-link dropdown-toggle ${isFinanceActive ? "active" : ""}`}
                                            to={`/${item.permanent_slug}`}
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            ref={dropdownRef}
                                            onClick={() => setIsOpen(!isOpen)}
                                        >
                                            {item.title}
                                        </NavLink>
                                        <ul className="dropdown-menu blogDropdown">
                                            {item.children.map((child) => (
                                                <li key={child.id}>
                                                    <NavLink
                                                        className="dropdown-item"
                                                        to={`/${child.permanent_slug}`}
                                                        onClick={handleItemClick}
                                                    >
                                                        {child.title}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ) : (
                                    <li key={item.id} className="nav-item">
                                        <NavLink className={item.permanent_slug == "blogs" ? `nav-link ${isBlogActive ? 'active' : ''}` : "nav-link"} aria-current="page" to={`/${item.permanent_slug} `}>
                                            {item.title}
                                        </NavLink>
                                    </li>
                                )
                            }
                            return null
                        })}

                        {/* <li className="nav-item">
                            <NavLink className="nav-link" aria-current="page" to={"/homepage"}>Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={`nav-link ${isBlogActive ? 'active' : ''}`}
                                to={"/blogs"}
                                activeclassname="active"
                            >
                                Blogs
                            </NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink className={`nav-link dropdown-toggle ${isFinanceActive ? "active" : ""}`} to={"/"} role="button" data-bs-toggle="dropdown" aria-expanded="false" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
                                Financing
                            </NavLink>
                            <ul className="dropdown-menu blogDropdown">
                                <li><NavLink className="dropdown-item" to={"/"} onClick={handleItemClick}>Credit Card</NavLink></li>
                                <li><NavLink className="dropdown-item" to={"/mortgage-calculator"} onClick={handleItemClick}>Mortgage</NavLink></li>
                            </ul>
                        </li> */}
                    </ul>
                    <NavLink className="navbar-brand mobileHide" to={"/homepage"}>
                        <img src={state?.logo ? `${fileUrl}storage/${state?.logo}` : Logo} className="img-fluid" alt="logo" />
                    </NavLink>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {groupedItems.map((item) => {
                            if (item?.alignment === "right" && item?.display_sections?.includes("header")) {
                                return (<li className="nav-item" key={item.id}>
                                    <button type="button" className="btn headerBtn" onClick={() => navigate(`/${item.permanent_slug}`)}>
                                        <TbHomeCheck /> {item.title}
                                    </button>
                                </li>)

                            }
                        })}

                        {/* <li className="nav-item">
                            <button type="button" className="btn headerBtn" onClick={() => handleNavigate()}>
                                <TbHomeCheck /> My Homes
                            </button>
                        </li> */}
                        <li className="nav-item dropdown mobileHide">
                            <NavLink className="headerBtn dropdown-toggle dropdownBtn" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {userDetail?.data?.profile ?
                                    <Image preview={false} src={`${fetchDetail_Url}/${userDetail?.data?.profile}`}></Image> :
                                    <FaRegUserCircle />}
                            </NavLink>
                            <ul className="dropdown-menu customDropdwn">
                                {groupedItems.map((item) => {
                                    if (item?.alignment === "right" && item?.display_sections?.includes("profile")) {
                                        return (
                                            <li><button type="button" className="dropdown-item" onClick={() => navigate(`/${item.permanent_slug}`)} >{item.title}</button></li>
                                        )
                                    }
                                })}
                                {/* <li><button type="button" className="dropdown-item" to={"#"} onClick={() => handleAccountNavigate()} >My Account</button></li>
                                <li><button type="button" className="dropdown-item" to={"#"} onClick={() => handleProfileNavigate()} >My Profile</button></li> */}
                                <li><button type="button" className="dropdown-item" to={"#"} onClick={() => handleLogout()} >Log Out</button></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
